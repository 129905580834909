import { BrowserRouter, Routes, Route } from "react-router-dom";
import DefaultLayout from "./layouts/defaultLayout";
import PaymentPage from "./pages/payment/payment";
import { useTranslation } from 'react-i18next';
import './App.css';
import TermsPage from "./pages/terms/terms";

function App() {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="payment/:paymentExtId" element={<PaymentPage />} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route path="terms" element={<TermsPage />} />
        </Route>
      </Routes>
  </BrowserRouter>
  );
}

export default App;
