import React from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as AirParkLogo } from '../../assets/icons/airpark_logo_plate_black.svg';
import Toast from "../../components/toast";
import { useMessages } from "../../context/messages";

export const DefaultLayout = () => {

    const { toast } = useMessages();

    return (
        <>
        <Outlet />
            <Toast display={toast.display} title={toast.title} description={toast.description} template={toast.template} />
        </>
    );
};

export default DefaultLayout;