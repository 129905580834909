import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          title: "AirPark",
          receiptCreated: "Receipt created",
          receiptForPayment: "Receipt for parking",
          transactionFee: "Transaction fee, incl. VAT",
          transactionFeeVat: "Transaction fee VAT",
          airParkFee: "AirPark fee, incl. VAT",
          airParkFeeVat: "AirPark fee VAT",
          parkingOwnerFee: "Property fee, incl. VAT",
          parkingOwnerVat: "Property fee VAT",
          parkingFee: "Parking fee, incl. VAT",
          parkingFeeVat: "VAT",
          total: "Total:",
          print: 'Print',
          organizationNumberAbbreviated: 'Org. no',
          vatNumber: 'VAT no',
          }
      },
      sv: {
        translation: {
          title: "AirPark",
          receiptCreated: "Kvitto skapat",
          receiptForPayment: "Kvitto för parkering",
          transactionFee: "Transaktionsavgift, inkl. moms",
          transactionFeeVat: "Transaktionsavgift moms",
          airParkFee: "AirPark-avgift, inkl. moms",
          airParkFeeVat: "AirPark-avgift moms",
          parkingOwnerFee: "Fastighetsavgift, inkl. moms",
          parkingOwnerVat: "Fastighetsavgift moms",
          parkingFee: "Parkeringsavgift, inkl. moms",
          parkingFeeVat: "Moms",
          total: "Totalt:",
          print: 'Skriv ut',
          organizationNumberAbbreviated: 'Org. nr',
          vatNumber: 'Momsreg. nr'
        }
      }
    }
  });

  export default i18n;