import React, { useState, useEffect } from 'react';
import { getReceipt } from '../../api/payments';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as AirParkLogo } from '../../assets/icons/ico_airpark_logo_no_bg.svg';
import { ReactComponent as TicketIcon } from '../../assets/icons/ico_ticket_green.svg';
import { ReactComponent as SpotBubble } from '../../assets/icons/ico_spot_bubble.svg';
import { ReactComponent as SpotIcon } from '../../assets/icons/ico_p_spot.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/ico_calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/ico_clock.svg';
import { ReactComponent as ElectricityIcon } from '../../assets/icons/ico_electricity_black.svg';
import { ReactComponent as IndoorIcon } from '../../assets/icons/ico_garage_black.svg';
import { ReactComponent as FencedIcon } from '../../assets/icons/ico_fence_black.svg';
import { ReactComponent as CarIcon } from '../../assets/icons/ico_car.svg';
import { ReactComponent as CreditCard } from '../../assets/icons/ico_credit_card.svg';
import { useTranslation } from 'react-i18next';

const TermsPage = ({}) => {
    const { paymentExtId } = useParams();
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(true);

    return (
      <>
      <div>
           
            <h1>ALLMÄNNA VILLKOR & INTEGRITETSPOLICY FÖR AIRPARK AB</h1>
            <br/>
            <p>Följande allmänna villkor (“Villkoren”) reglerar användningen av samtliga tjänster som
            AirPark AB, org.nr 559350-3138, (”AirPark”) tillhandahåller. Villkoren gäller för såväl dig som är
            innehavare av en parkeringsplats (”Uthyrare”/”Du”) som för dig som hyr en parkeringsplats
            (”Användare”/”Du”).</p>
            <br/>
            <p>AirPark tillhandahåller en plattform genom den mobila applikationen AirPark (”Appen”) som syftar till att
            underlätta kontakten mellan Uthyrare och Användare. Genom Appen kan Uthyrare och Användare
            komma i kontakt med varandra för att hyra ut respektive hyra parkeringsplatser.</p>
            <br/>
            <p>Utöver ovanstående tillhandahåller AirPark en webbportal genom webbsidan portal.airpark.app
            (”Webbportalen”), via vilken Uthyrare som nyttjar Webbportalen för tillgängliggörande samt uthyrning
            av mer än en parkeringsplats på av Uthyrare ägd fastighet (”Fastighetsägare”/”Uthyrare”/”Du”) har
            möjlighet att tillgängliggöra samt hyra ut parkeringsplatser till Användare via Appen.</p>
            <br/>
            <p>AirPark agerar endast som en förmedlare av kontakt mellan Uthyrare och Användare och är inte i något
            avseende part i det avtalsförhållande som uppstår mellan Uthyrare och Användare.</p>
            <br/>
            <p>När Du registrerar dig via Appen eller Webbportalen godkänner Du samtidigt Villkoren och ingår då ett
            bindande avtal med AirPark avseende din användning av de tjänster AirPark tillhandahåller via Appen,
            respektive Webbportalen. De tjänster AirPark tillhandahåller via Appen samt Webbportalen benämns
            gemensamt ”Tjänsten”.</p>
            <br/>
            <h2>1 TILLÄMPNINGSOMRÅDE</h2>
            <br/>
            <p>1.1 Villkoren gäller för samtliga användare av Tjänsten, såväl Uthyrare som Användare, och utgör
            ett bindande avtal mellan Uthyraren och AirPark, respektive mellan Användaren och AirPark.
            Därutöver reglerar Villkoren i tillämpliga delar det avtalsförhållande avseende uthyrning som
            uppstår mellan Uthyraren och Användaren.</p>
            <br/>
            <p>1.2 Villkoren gäller för all användning av Tjänsten utöver vad som framgår av följande:</p>
            <br/>
            <p>(i) Avsnitt 4 i Villkoren gäller endast för den som använder Tjänsten i egenskap av
            Uthyrare;</p>
            <br/>
            <p>(ii) Avsnitt 5 i Villkoren gäller endast för den som använder Tjänsten i egenskap av
            Användare;</p>
            <br/>
            <p>(iii) Avsnitt 7 i Villkoren gäller endast för den som använder Tjänsten i egenskap av
            Fastighetsägare; och</p>
            <br/>
            <p>(iv) Avsnitt 15 i Villkoren gäller endast för den som använder Tjänsten i egenskap av juridisk
            person.</p>
            <br/>
            <p>1.3 AirPark erbjuder enbart en tjänst för förmedling av parkeringsplatser, vilket innebär att de
            parkeringsmöjligheter som erbjuds via Appen inte erbjuds av AirPark utan av respektive
            Uthyrare. AirPark kan agera som agent för respektive Uthyrare och kan erhålla och kräva
            betalning för Uthyrares räkning, men AirPark hyr inte ut parkeringsplatser i eget namn.
            Avtalsförhållandet avseende uthyrning av parkering är enbart gällande mellan Uthyraren och
            Användaren.</p>
            <br/>
            <h2>2 ALLMÄNT OM DIN PROFIL</h2>
            <br/>
            <p>2.1 För att nyttja Tjänsten behöver Du registrera dig och skapa en profil. Det är kostnadsfritt att
            registrera sig och skapa en profil via både Appen och Webbportalen. Privatpersoner över 18 år
            och juridiska personer kan skapa en profil. Om en fysisk person skapar en profil åt en juridisk 
            2
            person garanterar den fysiska personen att vederbörande är behörig att företräda och binda
            den juridiska personen i enlighet med Villkoren.</p>
            <br/>
            <p>2.2 Genom att Du registrerar dig eller en juridisk person, samt skapar en profil i Appen eller
            Webbportalen, bekräftar Du att Du har läst och accepterar Villkoren för egen räkning och för
            eventuell juridisk persons räkning.</p>
            <br/>
            <p>2.3 Det är av vikt att den information som uppges i profilen är korrekt för att Tjänsten ska fungera
            på avsett sätt. Du intygar därför att all information i din profil är korrekt, fullständig och relevant.
            Du ansvarar för att löpande tillse att uppgifterna i din profil är korrekta.</p>
            <br/>
            <p>2.4 Du är ansvarig för alla följder som uppstår på grund av felaktig eller ofullständig information i
            din profil. Det innebär att Du ensam är ansvarig för de skador som uppstår till följd av din
            felaktiga eller ofullständiga information.</p>
            <br/>
            <p>2.5 AirPark förbehåller sig rätten att avsluta ditt konto om det innehåller oriktig information, om ditt
            användande av Tjänsten strider mot lag eller Villkoren, om din profil mottagit ett – enligt AirPark
            – högt antal negativa omdömen eller om det annars föreligger erforderliga skäl för AirPark att
            avsluta ditt konto.</p>
            <br/>
            <p>2.6 Du åtar dig att omedelbart meddela AirPark i händelse av obehörig användning av din profil.</p>
            <br/>
            <p>2.7 Du har rätt att när som helst sluta använda Tjänsten och säga upp eller radera din profil. Har
            Du en bekräftad bokning avseende hyra eller uthyrning av en parkeringsplats måste Du dock
            först avboka bokningen enligt bestämmelserna i punkt 8.1 respektive 8.2 i Villkoren.</p>
            <br/>
            <p>2.8 Om Du har en innestående utbetalning då din profil sägs upp eller raderas, kommer AirPark
            att betala ut beloppet till dig trots att Du vid betalningstillfället inte har en aktiv profil.</p>
            <br/>
            <h2>3 ALLMÄNT OM TJÄNSTEN</h2>
            <br/>
            <p>3.1 Du bekräftar härmed att AirPark endast tillhandahåller en tjänst där Uthyrare och Användare
            kan komma i kontakt med varandra. AirPark, Appen och Webbportalen syftar endast till att
            underlätta kontakten mellan Uthyrare och Användare. Du bekräftar att AirPark inte är part i
            avtalsförhållandet mellan Uthyraren och Användaren samt att AirPark därför inte har någon
            skyldighet gentemot varken Uthyraren eller Användaren, utöver vad som uttryckligen framgår
            av dessa Villkor.</p>
            <br/>
            <h2>3.2 Du accepterar och godkänner:</h2>
            <br/>
            <p>(i) att AirPark när som helst har rätt att uppdatera eller på annat sätt modifiera Appen,
            Webbportalen samt Villkoren;</p>
            <br/>
            <p>(ii) att AirPark inte kan garantera att Appen eller Webbportalen kommer att vara tillgänglig
            vid alla tillfällen samt fungera utan avbrott eller störningar;</p>
            <br/>
            <p>(iii) att AirPark förbehåller sig rätten att radera en parkeringsplats från Appen eller
            Webbportalen utan att något meddelande har lämnats på förhand; och</p>
            <br/>
            <p>(iv) att AirPark förbehåller sig rätten att ta bort en bokning som gjorts i Appen av en
            Användare utan att något meddelande har lämnats på förhand.</p>
            <br/>
            <h2>4 SÄRSKILDA VILLKOR FÖR UTHYRARE</h2>
            <br/>
            <p>4.1 Villkoren i detta avsnitt 4 gäller endast för den som använder Tjänsten i egenskap av Uthyrare.</p>
            <br/>
            <p>4.2 Genom Appen, eller i förekommande fall Webbportalen, kan Uthyraren annonsera
            parkeringsplats samt ingå avtal med de Användare som avser att hyra annonserad
            parkeringsplats. Uthyraren betalar en förmedlingsavgift till AirPark i samband med varje
            uthyrningstillfälle i enlighet med AirParks vid var tid gällande prisvillkor, vilka framgår av
            följande länk: prisvillkor ("Prisvillkoren”). För det fall att Uthyraren hyr ut parkeringsplats i 
            3
            andra hand kan Fastighetsägaren i enlighet med Prisvillkoren ha rätt till en andel av det
            hyresbelopp som Användare erlägger för Uthyrarens parkeringsplats.</p>
            <br/>
            <p>4.3 För att Tjänsten ska fungera på avsett vis är det viktigt att Uthyraren uppger de uppgifter som
            krävs för att möjliggöra upplåtelsen av parkeringsplatsen. Du behöver därför ange namn/
            företagsnamn, personnummer/organisationsnummer, postadress, e-postadress, telefonnummer, preciserade uppgifter om parkeringsplatsen samt kontouppgifter för att AirPark ska
            kunna betala ut den del av ersättningen från Användaren som AirPark uppburit för din räkning.</p>
            <br/>
            <p>4.4 Uthyraren är ansvarig för att uppgifterna i annonseringen är korrekta och fullständiga.
            Uthyraren är vidare ansvarig för att denne har rätt att hyra ut parkeringsplatsen enligt samtliga
            tillämpliga lagar och eventuella avtal mellan Uthyraren och tredje man.</p>
            <br/>
            <p>4.5 Uthyraren ska omedelbart meddela AirPark vid förändrade omständigheter, exempelvis vid
            adressändring eller om Uthyraren av någon anledning inte längre har möjlighet att upplåta
            parkeringsplatsen.</p>
            <br/>
            <p>4.6 Uthyraren ansvarar för att parkeringsplatsen är i fullgott skick och anpassad för ändamålet i
            samband med respektive uthyrning.</p>
            <br/>
            <p>4.7 När en Användare bokar en parkeringsplats, accepterar villkoren för upplåtelsen och erlägger
            betalning uppstår ett hyresavtal mellan Användaren och Uthyraren som Uthyraren blir bunden
            av. Uthyraren måste då tillhandahålla parkeringsplatsen till Användaren enligt överenskommelsen. Oberoende av vilket skäl som ligger till grund för Uthyrarens underlåtenhet att
            tillhandahålla parkeringsplatsen ska den erlagda hyresavgiften återbetalas till Användaren.</p>
            <br/>
            <p>4.8 Uthyraren bekräftar att AirPark har rätt att för Uthyrarens räkning administrera, samla in,
            uppbära och återbetala betalning som Användaren erlägger i samband med hyrandet av
            parkeringsplatsen.</p>
            <br/>
            <p>4.9 Uthyraren är medveten om att upplåtelse av parkeringsplats kan medföra skattskyldighet.
            Uthyraren ansvarar ensam för eventuella skattekonsekvenser som kan uppkomma i anledning
            av upplåtande av dennes parkeringsplats. Det ankommer på Uthyraren att inhämta nödvändig
            information avseende tillämpliga skatteregler.</p>
            <br/>
            <p>4.10 Övervakning av parkeringsvillkorens efterlevnad</p>
            <br/>
            <p>4.10.1 Punkterna 4.10.2 till och med 4.10.4 nedan ska äga tillämpning från det att Uthyraren aktiverar
            övervakningsfunktionen i Appen eller Webbportalen.</p>
            <br/>
            <p>4.10.2 Om någon parkerar ett fordon i strid mot förbud eller villkor som gäller för parkeringsplatsen
            som annonseras via Appen (”Olovlig Parkering”), ges AirPark rätt att ålägga denne att betala
            en kontrollavgift i enlighet med lag (1984:318) om kontrollavgift vid olovlig parkering.</p>
            <br/>
            <p>4.10.3 Uthyraren ska – efter det att AirPark erhållit kontrollavgift – äga rätt att erhålla sådan del av
            kontrollavgiften som framgår av Prisvillkoren. I de fall Uthyraren hyr ut en parkeringsplats i
            andra hand fördelas kontrollavgiften i stället mellan AirPark och Fastighetsägaren.</p>
            <br/>
            <p>4.10.4 Uthyraren åtar sig att tillse att parkeringsvillkoren tydliggörs genom att förse parkeringsplatsen
            som annonseras via Appen med erforderliga skyltar av vilka det framgår att parkeringsplatsen
            inte är en allmän sådan. Sådan skyltning ska ske med vägmärken i enlighet med lag
            (1984:318) om kontrollavgift vid olovlig parkering och ska vara genomförd senast då
            övervakningsfunktionen i Appen alternativt Webbportalen aktiveras. AirPark erbjuder skyltar
            till försäljning och erbjuder sig att vid förfrågan vara behjälpligt i skälig utsträckning med
            konsultation avseende skyltning.</p>
            <br/>
            <p>5 SÄRSKILDA VILLKOR FÖR ANVÄNDARE</p>
            <br/>
            <p>5.1 Villkoren i detta avsnitt 5 gäller för den som använder Tjänsten i egenskap av Användare.
            4</p>
            <br/>
            <p>5.2 Genom Appen kan Användaren hitta tillgängliga parkeringsplatser samt etablera kontakt med
            Uthyrare. För nyttjandet av en parkeringsplats erlägger Användaren ersättning som betalas till
            AirPark via en samarbetspartner till AirPark.</p>
            <br/>
            <p>5.3 Användaren bekräftar att varje Uthyrare själv är ansvarig för att den information som lämnas i
            samband med annonseringen är korrekt och att AirPark därför inte bär något ansvar för felaktig
            eller vilseledande information.</p>
            <br/>
            <p>5.4 Genom att Användaren bokar en parkeringsplats, accepterar villkoren för upplåtelsen samt
            erlägger betalning genom Appen uppstår ett hyresavtal mellan Användaren och Uthyraren
            som Användaren blir bunden av.</p>
            <br/>
            <p>5.5 Användaren ansvarar för att varken förare eller eventuella passagerare skräpar ner eller på
            något annat sätt förstör parkeringsplatsen eller området kring parkeringsplatsen. Användaren
            godkänner vidare att denne ska lämna Uthyrarens parkeringsplats i samma skick som den var
            i när den togs i besittning, senast vid parkeringstidens utgång.</p>
            <br/>
            <p>5.6 Användaren godkänner att Användaren inte får nyttja Uthyrarens parkeringsplats efter den
            överenskomna tiden.</p>
            <br/>
            <p>6 HYRESAVTAL</p>
            <br/>
            <p>6.1 Ett hyresavtal uppstår mellan Uthyrare och Användare då Användaren bokar Uthyrarens
            parkeringsplats i Appen. Genom bokningen accepterar Användaren Uthyrarens villkor i fråga
            om pris och tid för upplåtelsen av parkeringsplatsen. AirParks funktion är att förmedla
            kontakten mellan Uthyrare och Användare samt att underlätta betalningen. AirPark är således
            inte part i hyresavtalet.</p>
            <br/>
            <p>6.2 Användaren är ansvarig för aktuell parkeringsplats under den tid som Användaren nyttjar
            sådan parkeringsplats. Användaren har inte rätt att upplåta parkeringsplats i andra hand om
            inte annat framgår.</p>
            <br/>
            <p>6.3 Det fordon som Användaren parkerar på parkeringsplats måste vara försett med lagenliga
            registreringsskyltar.</p>
            <br/>
            <p>6.4 Användaren har endast rätt att nyttja parkeringsplats under den överenskomna bokningstiden.</p>
            <br/>
            <p>6.5 Användaren är ensamt ansvarig för all skada som AirPark, Uthyraren och/eller tredje man
            åsamkas på grund av Användarens underlåtenhet att iaktta sina skyldigheter enligt Villkoren.</p>
            <br/>
            <p>6.6 Uthyraren garanterar att denne har rätt att upplåta aktuell parkeringsplats i enlighet med
            Villkoren till tredje man.</p>
            <br/>
            <p>6.7 Uthyraren garanterar att parkeringsplatsen står till Användarens förfogande under hela den tid
            som bokningen avser. Uthyraren ansvarar även för att det inte finns något hinder under
            bokningstiden vad gäller till- och frånfartsvägen till aktuell parkeringsplats. Uthyraren ansvarar
            dock inte för eventuella hinder som finns på allmän väg. Uthyraren åtar sig även att tillse att
            Användaren inte hindras från tillträde till parkeringsplats på grund av exempelvis snö eller
            annat hinder.</p>
            <br/>
            <p>6.8 Användaren äger rätt att avboka en bokad parkeringsplats i enlighet med avsnitt 8.1 i Villkoren.
            Användaren äger även rätt att avsluta en bokad och påbörjad parkering i förtid och kan då i
            enlighet med Prisvillkoren ha rätt till viss återbetalning.</p>
            <br/>
            <p>6.9 Uthyraren äger rätt att avboka en bokad parkeringsplats i enlighet med avsnitt 8.2 i Villkoren.</p>
            <br/>
            <p>6.10 Om Användaren inte ges tillgång till parkeringsplatsen under den överenskomna tiden har
            Användaren möjlighet att reklamera enligt punkt 9.2 i Villkoren.</p>
            <br/>
            <p>6.11 När bokningstiden löper ut upphör hyresavtalet mellan Användaren och Uthyraren att gälla.</p>
            <br/>
            <p>5</p>
            <br/>
            <p>7 SÄRSKILDA VILLKOR FÖR FASTIGHETSÄGARE</p>
            <br/>
            <p>7.1 Allmänt</p>
            <br/>
            <p>7.1.1 Detta avsnitt 7 gäller för den som använder Tjänsten i egenskap av Fastighetsägare.</p>
            <br/>
            <p>7.1.2 Vid eventuella konflikter mellan detta avsnitt 7 och övriga avsnitt i Villkoren, ska
            bestämmelserna i detta avsnitt 7 äga företräde mellan Fastighetsägaren och AirPark.</p>
            <br/>
            <p>7.1.3 Fastighetsägaren äger en fastighet på vilken det finns ett parkeringsområde med mer än en
            parkeringsplats (”Parkeringen”). Fastighetsägaren måste registrera Parkeringen i
            Webbportalen för att kunna nyttja de tjänster som AirPark tillhandahåller.</p>
            <br/>
            <p>7.1.4 Om Fastighetsägaren och AirPark har ingått ett separat avtal avseende Fastighetsägarens
            användning av Webbportalen (”Avtalet”), ska bestämmelserna i Avtalet äga företräde vid
            eventuella konflikter mellan bestämmelserna i Villkoren respektive i Avtalet.</p>
            <br/>
            <p>7.2 Uthyrning av parkeringsplatser genom parkeringstillstånd</p>
            <br/>
            <p>7.2.1 Fastighetsägaren ges möjlighet att tillgängliggöra parkeringsplatser på Parkeringen för
            långtidsuthyrning genom parkeringstillstånd som administreras i Webbportalen.</p>
            <br/>
            <p>7.2.2 I samband med varje debitering avseende parkeringstillstånd som administreras och debiteras
            via Webbportalen erläggs en förmedlingsavgift i enlighet med Prisvillkoren till AirPark.</p>
            <br/>
            <p>7.2.3 I de fall Fastighetsägaren administrerar parkeringstillstånd i Webbportalen utan att hantera
            debiteringen av parkeringstillstånden genom Webbportalen utgår en avgift till AirPark
            uppgående till det belopp som följer av Prisvillkoren.</p>
            <br/>
            <p>7.3 Direktuthyrning av parkeringsplatser</p>
            <br/>
            <p>7.3.1 Fastighetsägaren ges möjlighet att tillgängliggöra parkeringsplatser på Parkeringen för
            korttidsuthyrning genom registrering av Parkeringen i Webbportalen.</p>
            <br/>
            <p>7.3.2 För det fall att Fastighetsägaren inte har för avsikt att tillgängliggöra Parkeringen för
            korttidsuthyrning för samtliga Användare ansvarar Fastighetsägaren för att själv, via
            Webbportalen, administrera vilka Användare som ska kunna få tillgång till Parkeringen, ett så
            kallat ”Community”.</p>
            <br/>
            <p>7.3.3 I samband med varje uthyrningstillfälle erläggs en förmedlingsavgift till AirPark. Förmedlingsavgiften uppgår till det belopp som följer av Prisvillkoren.</p>
            <br/>
            <p>7.4 Områdesskyltning</p>
            <br/>
            <p>7.4.1 För att kunna nyttja de tjänster AirPark erbjuder via Webbportalen förutsätts att Fastighetsägaren i tillämpliga fall markerar upp respektive parkeringsplats som tillgängliggörs på
            Parkeringen med hjälp av särskilda numreringsskyltar (”Områdesskyltning”).</p>
            <br/>
            <p>7.4.2 För det fall det inte finns en befintlig Områdesskyltning åtar sig Fastighetsägaren att tillse att
            sådan sätts upp snarast möjligt. Fastighetsägaren ska stå för samtliga kostnader hänförliga till
            Områdesskyltning. AirPark erbjuder skyltar för Områdesskyltning till försäljning.</p>
            <br/>
            <p>7.5 Andrahandsuthyrning</p>
            <br/>
            <p>7.5.1 Fastighetsägaren bekräftar att fysisk eller juridisk person med nyttjanderätt till parkeringsplats
            på Parkeringen (”Hyresgäst”) äger rätt att i andra hand via Appen hyra ut sådan parkeringsplats på Parkeringen som sådan Hyresgäst förfogar över.</p>
            <br/>
            <p>7.5.2 I samband med varje andrahandsuthyrningstillfälle erläggs en förmedlingsavgift till AirPark.
            Förmedlingsavgiften uppgår till det belopp som framgår av Prisvillkoren.</p>
            <br/>
            <p>7.5.3 Fastighetsägaren är berättigad till ersättning för andrahandsuthyrning för det fall att
            Fastighetsägaren skriftligen meddelar AirPark att denne vill mottaga sådan ersättning
            (”Ersättningen”). AirPark ska senast inom en (1) vecka från mottagande av sådant skriftligt </p>
            <br/>
            <p>6</p>
            <br/>
            <p>meddelande från Fastighetsägaren påbörja att erlägga Ersättningen vid sådan andrahandsuthyrning. Ersättningen uppgår till den procentuella andel av hyresavgiften som bestämts av
            Fastighetsägaren i Webbportalen.</p>
            <br/>
            <p>7.6 AirPass-systemet</p>
            <br/>
            <p>7.6.1 AirPass-systemet är en produkt som efter korrekt installation möjliggör för Användare med ett
            giltigt parkeringstillstånd eller en bokad parkering att öppna garageporten till Parkeringen via
            Appen (”AirPass-systemet”).</p>
            <br/>
            <p>7.6.2 Punkterna 7.6.3 till och med 7.6.9 nedan ska äga tillämpning från det att Fastighetsägaren har
            beställt hårdvaran till AirPass-systemet av AirPark.</p>
            <br/>
            <p>7.6.3 Fastighetsägaren åtar sig att dels anlita certifierad elektriker för installation av hårdvaran till
            AirPass-systemet, dels bekosta installation och eventuell service avseende hårdvaran till
            AirPass-systemet. AirPark kan vid förfrågan från Fastighetsägaren rekommendera lämplig
            elektriker för ovan ändamål.</p>
            <br/>
            <p>7.6.4 Fastighetsägaren åtar sig att vårda och underhålla hårdvaran till AirPass-systemet.
            Fastighetsägaren ska genast meddela AirPark om hårdvaran skulle skadas eller annars vara
            defekt. Om hårdvaran behöver repareras eller bytas ut utan att det beror på omständigheter
            hänförliga till AirPark, ska Fastighetsägaren stå för samtliga kostnader hänförliga därtill.</p>
            <br/>
            <p>7.6.5 Överenskommelsen om AirPass-systemet gäller från och med beställningen av hårdvaran och
            tolv (12) månader framåt. Om överenskommelsen om AirPass-systemet inte sägs upp
            skriftligen senast tre (3) månader dessförinnan, förlängs överenskommelsen att gälla tills
            vidare med tre (3) månaders uppsägningstid. Uppsägning från Fastighetsägarens sida ska
            skickas till info@airpark.app.</p>
            <br/>
            <p>7.6.6 I samband med beställningen av AirPass-systemet faktureras Fastighetsägaren en
            uppstartsavgift uppgående till det belopp som följer av Prisvillkoren.</p>
            <br/>
            <p>7.6.7 Fastighetsägaren ska, utöver vad som anges i punkt 7.6.6 ovan, betala en månadsavgift
            uppgående till det belopp som följer av Prisvillkoren. Kostnaden härför ska i första hand kvittas
            mot ersättning som Fastighetsägaren har rätt att erhålla enligt Villkoren och i andra hand
            faktureras Fastighetsägaren.</p>
            <br/>
            <p>7.6.8 AirPark äger rätt att efter den initiala bindningstiden om tolv (12) månader justera
            Fastighetsägarens månadsavgift för AirPass-systemet genom att ge Fastighetsägaren tre (3)
            månaders skriftlig varsel därom. Om Fastighetsägaren inte accepterar prisändringen äger
            Fastighetsägaren rätt att säga upp överenskommelsen om AirPass-systemet till upphörande
            senast den dag prisändringen börjar gälla. Fastighetsägaren påverkas i sådana fall inte av
            prisändringen under uppsägningstiden.</p>
            <br/>
            <p>7.6.9 Hårdvaran till AirPass-systemet är och förblir AirParks egendom, vilken endast lånas ut till
            Fastighetsägaren under tiden som AirPass-systemet ska nyttjas. När överenskommelsen om
            AirPass-systemet mellan Fastighetsägaren och AirPark upphör, ska Fastighetsägaren på
            egen bekostnad återsända hårdvaran avseende AirPass-systemet och tillse att denna är
            AirPark tillhanda senast inom trettio (30) dagar från dess att överenskommelsen om AirPasssystemet upphörde.</p>
            <br/>
            <p>8 AVBOKNING</p>
            <br/>
            <p>8.1 Användaren har rätt att kostnadsfritt fram till bokningstidens start avboka en genom Appen
            bokad parkeringsplats. Avbokning sker genom en funktion i Appen.</p>
            <br/>
            <p>8.2 Uthyraren har rätt att kostnadsfritt avboka en parkeringsplats som bokats av en Användare
            genom att senast fjorton (14) dagar före bokningstidens start skicka ett meddelande härom till
            hello@airpark.app. </p>
            <br/>
            <p>7</p>
            <br/>
            <p>9 RAPPORTERING OCH REKLAMATION</p>
            <br/>
            <p>9.1 Om Uthyraren eller någon annan Användare upptäcker att någon gjort sig skyldig till en Olovlig
            Parkering kan Uthyraren eller annan Användare rapportera händelsen till AirPark. Uthyraren
            eller den andra Användaren ska då dokumentera händelsen i form av fotografier, filmklipp eller
            annan dokumentation som kan bevisa händelsen. Det är av vikt att registreringsnumret på
            aktuellt fordon framgår av dokumentationen. Uthyraren eller annan Användare rapporterar den
            Olovliga Parkeringen genom meddelande i den särskilda funktionen för detta ändamål i
            Appen. Till meddelandet ska dokumentation som bevisar den Olovliga Parkeringen bifogas.
            AirPark kommer därefter bedöma om det är fråga om en Olovlig Parkering baserat på det
            material som Uthyraren eller den andra Användaren har skickat in.</p>
            <br/>
            <p>9.2 Om Användaren efter bokning och betalning för en parkeringsplats som erbjudits av en
            Uthyrare och annonserats via Appen inte ges tillgång till parkeringsplatsen under den
            överenskomna bokningstiden, och Uthyraren inte avbokat i enlighet med punkt 8.2 ovan, kan
            Användaren reklamera händelsen till AirPark. Användaren åtar sig då att dokumentera
            händelsen i form av fotografier, filmklipp eller annan dokumentation som kan bevisa
            händelsen. För det fall att det redan står ett fordon på parkeringsplatsen är det särskilt viktigt
            att registreringsnumret på det parkerade fordonet framgår. Reklamation sker genom
            meddelande i den särskilda funktionen i Appen. I meddelandet behöver Användaren uppge
            bokningsreferens, en beskrivning av på vilket sätt som Användaren inte givits tillgång till
            parkeringsplatsen samt dokumentation för att bevisa händelsen. Meddelandet ska ha skickats
            före den överenskomna bokningstiden löpt ut. AirPark kommer därefter att fatta beslut
            angående reklamationen baserat på det material som Användaren har skickat in. Vid godkänd
            reklamation återbetalar AirPark den erlagda hyresavgiften avseende parkeringsplatsen.</p>
            <br/>
            <p>10 BETALNING</p>
            <br/>
            <p>10.1 För varje bokad parkeringsplats ska Användaren erlägga en förskottsbetalning avseende
            hyresavgiften. Hyresavgiften består av hyresbeloppet till Uthyraren samt en förmedlingsavgift
            till AirPark. Det totala beloppet framgår vid bokningen.</p>
            <br/>
            <p>10.2 AirParks förmedlingsavgift fastställs i enlighet med Prisvillkoren. AirParks rätt till förmedlingsavgift uppstår när ett hyresavtal mellan Uthyraren och Användaren kommer till stånd, under
            förutsättning att avbokning inte sker i enlighet med Villkoren.</p>
            <br/>
            <p>10.3 AirParks betalning av hyresbeloppet till Uthyraren sker i efterskott senast den sista vardagen
            nästföljande månad. Utbetalningen hanteras av Airparks betalningsleverantör och sker via en
            extern betalningsplattform. Uthyraren ansvarar för att skapa och upprätthålla ett giltigt konto
            på den externa betalningsplattformen för att möjliggöra mottagande av utbetalningar från
            AirPark.</p>
            <br/>
            <p>10.4 AirPark förbehåller sig rätten att byta betalningsleverantör. Aktuell betalningsleverantör
            framgår av Prisvillkoren.</p>
            <br/>
            <p>11 AIRPARKS ANSVAR</p>
            <br/>
            <p>11.1 AirPark tillhandahåller endast Tjänsten, vilken underlättar kontakten mellan Uthyrare och
            Användare. AirPark har därför ingen ersättningsskyldighet mot vare sig Uthyrare eller
            Användare annat än avseende de åtaganden som AirPark uttryckligen åtagit sig i enlighet med
            Villkoren.</p>
            <br/>
            <p>11.2 AirPark ansvarar inte i något fall för direkta eller indirekta skador och/eller förluster som
            uppstått för Uthyraren, Användaren eller tredje man i samband med användningen av
            Tjänsten.</p>
            <br/>
            <p>11.3 AirPark ansvarar inte för skador till följd av driftsavbrott eller andra störningar i Appen eller
            Webbportalen, utebliven vinst eller ersättningskrav från tredje man.</p>
            <br/>
            <p>8</p>
            <br/>
            <p>12 BEHANDLING AV PERSONUPPGIFTER</p>
            <br/>
            <p>I samband med att Du registrerar dig och skapar en profil registreras dina personuppgifter
            beroende på registreringssätt i Appen, alternativt Webbportalen. AirPark värnar om att dina
            personuppgifter ska behandlas på ett integritetssäkert sätt med god skyddsnivå och i enlighet
            med tillämplig dataskyddslagstiftning. AirPark behandlar personuppgifter i enlighet med
            AirParks vid var tid gällande integritetsskyddspolicy, Bilaga 1.</p>
            <br/>
            <p>13 IMMATERIELLA RÄTTIGHETER</p>
            <br/>
            <p>AirPark, och i tillämpliga fall dess licensgivare, äger samtliga immateriella rättigheter, inklusive
            upphovsrätt, rättigheter till varumärke och know-how kopplade till Appen, Webbportalen
            och/eller Tjänsten. Du äger inte rätt att kopiera, ändra, tillgodogöra dig, modifiera, distribuera
            eller på annat sätt bereda dig tillgång till sådana immateriella rättigheter som ägs av AirPark
            eller dess licensgivare. Du ges enbart en rätt att använda Appen, Webbportalen och Tjänsten
            i enlighet med Villkoren.</p>
            <br/>
            <p>14 ÖVRIGA BESTÄMMELSER</p>
            <br/>
            <p>14.1 Villkoren innehåller den fullständiga regleringen av alla frågor som berörs i Villkoren. AirPark
            förbehåller sig rätten att uppdatera Villkoren. Om sådan uppdatering sker kommer Du att
            meddelas om detta i samband med att Du använder Appen eller Webbportalen första gången
            efter att Villkoren har uppdaterats. Genom att fortsätta använda Appen eller Webbportalen
            efter att Villkoren har uppdaterats godkänner Du sådan uppdatering.</p>
            <br/>
            <p>14.2 Skulle någon bestämmelse eller del därav i Villkoren befinnas vara ogiltig, ska detta inte
            innebära att Villkoren i dess helhet är ogiltiga, utan endast att bestämmelsen i fråga jämkas
            eller bortses från.</p>
            <br/>
            <p>14.3 AirPark ska ha rätt att överlåta alla typer av rättigheter som följer av Villkoren till tredje man.</p>
            <br/>
            <p>14.4 Villkoren ska tolkas och tillämpas i enlighet med svensk lag.</p>
            <br/>
            <p>15 TVISTER</p>
            <br/>
            <p>15.1 Punkterna 15.2 och 15.3 ska äga tillämpning i de fall Användaren, Uthyraren eller
            Fastighetsägaren är en juridisk person.</p>
            <br/>
            <p>15.2 Tvister som uppstår i anledning av Villkoren ska slutligt avgöras genom skiljedomsförfarande
            administrerat av SCC Skiljedomsinstitut (SCC). Regler för Förenklat skiljeförfarande ska
            tillämpas om inte SCC med beaktande av målets svårighetsgrad, tvisteföremålets värde och
            övriga omständigheter bestämmer att Skiljedomsregler ska tillämpas. I sistnämnda fall ska
            SCC också bestämma om skiljenämnden ska bestå av en eller tre skiljedomare.
            Skiljeförfarandets säte ska vara Helsingborg. Språket för förfarandet ska vara svenska.</p>
            <br/>
            <p>15.3 Parterna förbinder sig att, utan begränsningar i tiden, inte utan tvingande skäl avslöja
            förekomsten av eller innehållet i skiljedom i anledning av Villkoren eller information om
            förhandlingar, skiljeförfarande eller medling i anledning av Villkoren.</p>
            <br/>
            <p>9</p>
            <br/>
            <p>BILAGA 1</p>
            <br/>
            <p>INTEGRITETSPOLICY FÖR AIRPARK AB</p>
            <br/>
            <p>AirPark AB, org.nr. 559350-3138, (”Bolaget”) värnar om att dina personuppgifter ska behandlas på ett
            integritetssäkert sätt med god skyddsnivå och i enlighet med tillämplig dataskyddslagstiftning.
            Denna integritetspolicy beskriver hur Bolaget behandlar personuppgifter om dig som är innehavare av
            en parkeringsplats (”Uthyrare/du”) och dig som hyr en parkeringsplats (”Användare/du”).
            Vid frågor om Bolagets integritetsskydd är du alltid välkommen att kontakta oss. Bolagets
            kontaktuppgifter framgår nedan under rubriken ”Kontaktinformation”.</p>
            <br/>
            <p>1 PERSONUPPGIFTSANSVARIG</p>
            <br/>
            <p>Bolaget är personuppgiftsansvarig för behandlingen av dina personuppgifter och är därför
            ansvarig för att dina uppgifter hanteras på ett korrekt och säkert sätt i enlighet med tillämplig
            lagstiftning.</p>
            <br/>
            <p>2 VILKA PERSONUPPGIFTER HANTERAR BOLAGET?</p>
            <br/>
            <p>2.1 Personuppgifter är alla upplysningar och all slags information som direkt eller indirekt
            kan hänföras till en person som är i livet. Det är uppgifter om dig och din person.
            Exempel på personuppgifter är namn, kontaktuppgifter, registreringsnummer och IPadress.</p>
            <br/>
            <p>2.2 Med behandling avses varje typ av åtgärd som vidtas med personuppgifter såsom
            insamling, lagring, användning, bearbetning och överföring av personuppgifter.</p>
            <br/>
            <p>2.3 Uthyrare:</p>
            <br/>
            <p>Bolaget samlar in och lagrar följande information om dig som Bolaget behöver för att kunna
            kontakta dig och fullgöra Bolagets åtaganden gentemot dig som Uthyrare.
            <br/>
            • Person- och kontaktinformation, såsom namn/företagsnamn, personnummer/organisationsnummer, adress, telefonnummer och e-postadress.
            <br/>
            • Uppgifter som du förmedlar till Bolaget per e-post, via våra sociala medier eller genom
            andra kommunikationskanaler.
            <br/>
            • Betalnings- och köphistorik.
            <br/>
            • Eventuella avtal med Bolaget.
            <br/>
            • Betalningsinformation, såsom bankuppgifter, kontonummer och i förekommande fall
            IBAN, SWIFT m.m.
            <br/>
            • Uppgifter om reklamationer och garantiärenden.
            <br/>
            • Lokaliseringsuppgifter.
            <br/>
            • Uppgifter om uthyrda parkeringsplatser.</p>
            <br/>
            <p>2.4 Användare:</p>
            <br/>
            <p>• Bolaget samlar in och lagrar följande information om dig som Bolaget behöver för att
            kunna kontakta dig som Användare.
            10
            <br/>
            • Person- och kontaktinformation, såsom namn, adress, personnummer, telefonnummer
            och e-postadress.
            <br/>
            • Registreringsnummer för fordon samt fordonstyp.
            <br/>
            • Uppgifter som du förmedlar till Bolaget per e post, via våra sociala medier eller genom
            andra kommunikationskanaler.
            <br/>
            • Betalnings- och köphistorik.
            <br/>
            • I förekommande fall uppgifter om telefonsamtal med Bolagets växel eller kundtjänst.
            <br/>
            • Lokaliseringsuppgifter.
            <br/>
            • Uppgifter från övervakningskameror och andra kameror, såsom bilder och filmer i
            anslutning till hyrda parkeringsplatser.
            <br/>
            • Uppgifter om parkeringshistorik såsom datum, klockslag och geografisk plats för
            användning av Bolagets mobilapplikation.
            <br/>
            2.5 Potentiella kunder, leverantörer och samarbetspartners samt företagsrepresentanter
            för potentiella kunder, leverantörer och samarbetspartners:
            <br/>
            • Bolaget kan samla in och lagra följande information om dig i egenskap av att du är en
            potentiell kund, leverantör eller samarbetspartner till Bolaget eller att du är
            företagsrepresentant för ett bolag som är potentiell kund, leverantör eller
            samarbetspartner till Bolaget. Bolaget behöver dessa uppgifter för att kunna kontakta dig
            i din egenskap av (potentiell) kund, leverantör eller samarbetspartner till Bolaget eller
            representant för (potentiell) kund, leverantör eller samarbetspartner till Bolaget och för att
            fullgöra Bolagets åtaganden gentemot dig eller bolaget för vilket du är representant.
            <br/>
            • Person- och kontaktinformation, såsom namn, adress, telefonnummer, e-postadress, titel,
            befattning och arbetsgivare.</p>
            <br/>
            <p>2.6 Besökare av Bolagets hemsida:</p>
            <br/>
            <p>• I samband med att du besöker Bolagets hemsida samlar vi in följande information om dig
            som Bolaget behöver för att kunna förbättra, effektivisera, förenkla och utveckla vår
            hemsida.
            <br/>
            • Tekniska uppgifter, såsom IP-adress, MAC-adress, URL, unik enhets ID, nätverkets och
            enhetens prestanda, webbläsare, språk- och identifikationsinställningar, geografisk
            position, operationssystem, andra uppgifter från cookies eller liknande mekanismer
            (enhetsinformation).</p>
            <br/>
            <p>3 VÅR BEHANDLING AV DINA PERSONUPPGIFTER</p>
            <br/>
            <p>För vilka ändamål vi avser att behandla dina personuppgifter samt vilken laglig grund vi har
            för respektive behandling följer av tabellerna nedan.</p>
            <br/>
            <p>3.1 Uthyrare och Användare:</p>
            <br/>
            <p>Ändamål Laglig grund
            För att kunna hålla kontakten med dig. Behandlingen är nödvändig för Bolagets
            berättigade intresse av att ha kontakt med
            dig i syfte att tillhandahålla tjänsten
            (intresseavvägning).
            För att kunna tillhandahålla tjänsten. Behandlingen är nödvändig för att
            tillhandahålla tjänsten (fullgöra avtal).
            11
            För att uppfylla författningsreglerade krav,
            exempelvis säkerhetskrav och
            bokföringskrav.
            Behandlingen är nödvändig för att fullgöra
            Bolagets rättsliga förpliktelser.
            För att möjliggöra marknadsföring och
            kommunikation om Bolagets varumärke
            och tjänster (t.ex. utskick av nyhetstidning
            och annat marknadsföringsmaterial,
            inbjudan till Bolagets evenemang, möten
            och andra sammankomster m.m.).
            Behandlingen är nödvändig för Bolagets
            berättigade intresse av att marknadsföra
            sina tjänster (intresseavvägning).
            För att genomföra undersökningar rörande
            Bolagets tjänst.
            Behandlingen är nödvändig för Bolagets
            berättigade intresse av att utvärdera,
            utveckla och förbättra sitt varumärke, sina
            tjänster och sin marknadsföring
            (intresseavvägning).
            För att säkerställa betalning och analysera
            köphistorik i syfte att erbjuda dig rätt tjänst
            och marknadsföring.
            Behandlingen är nödvändig för att
            tillhandahålla tjänsten (fullgöra avtal).
            Behandlingen är nödvändig för Bolagets
            berättigade intresse av att erbjuda dig
            relevant marknadsföring med hänsyn till
            dina tidigare köp (intresseavvägning).
            För att kunna motta betalning vid köp av
            Bolagets tjänster och service.
            Behandlingen är nödvändig för att
            tillhandahålla tjänsten (fullgöra avtal).
            För att kunna bemöta och kompensera dig
            vid reklamationer och garantiärenden.
            Behandlingen är nödvändig för att
            tillhandahålla tjänsten (fullgöra avtal).</p>
            <br/>
            <p>3.2 Besökare av Bolagets hemsida och applikation:</p>
            <br/>
            <p>Ändamål Laglig grund
            För att säkerställa driften av Bolagets
            hemsida och applikation.
            För att kunna utveckla Bolagets hemsida
            för att anpassa den bättre utifrån hur
            hemsidan används.
            Behandlingen är nödvändig för Bolagets
            berättigade intresse av att förbättra,
            effektivisera, förenkla och utveckla sin
            hemsida och i syfte att attrahera fler
            Användare/Uthyrare och att öka graden av
            återkommande Användare/Uthyrare
            (intresseavvägning).</p>
            <br/>
            <p>4 HUR LÄNGE BEVARAR VI DINA PERSONUPPGIFTER?</p>
            <br/>
            <p>4.1 Dina personuppgifter bevaras så länge det finns ett behov av att bevara dem för att
            fullgöra de ändamål som uppgifterna samlades in för i enlighet med denna
            integritetspolicy. Därefter kommer dina uppgifter att raderas.</p>
            <br/>
            <p>4.2 Geolokaliseringsdata sparas i upp till 60 dagar efter att parkeringen avslutats.
            Därefter kommer dessa uppgifter att raderas.</p>
            <br/>
            <p>4.3 Vissa personuppgifter kommer, i syfte att uppfylla relevant bokföringslagstiftning, att
            sparas i sju år, räknat från utgången av det kalenderår då räkenskapsåret, till vilken
            informationen hörde, avslutades.</p>
            <br/>
            <p>12</p>
            <br/>
            <p>4.4 Kontaktuppgifter till företagsrepresentanter lagras under den tid Bolaget bedömer att
            uppgifterna är nödvändiga för att upprätthålla relationen med
            företaget/organisationen. Radering ska ske när Bolaget får kännedom om att
            uppgifterna inte längre är adekvata eller relevanta för ändamålet, eller på begäran av
            kontaktpersonerna.</p>
            <br/>
            <p>4.5 För närmare information om hur länge Bolaget lagrar specifika personuppgifter var
            vänlig kontakta Bolaget. Kontaktuppgifter framgår under avsnittet
            ”Kontaktinformation” nedan.</p>
            <br/>
            <p>5 TILL VILKA LÄMNAR BOLAGET UT DINA PERSONUPPGIFTER?</p>
            <br/>
            <p>5.1 Bolaget lämnar inte ut personuppgifter till tredje part, förutom i situationer då detta
            krävs för att uppfylla en lagstadgad skyldighet eller för att fullfölja Bolagets åtaganden
            mot dig, kunder och/eller samarbetspartners. Dina personuppgifter kommer inte att
            säljas till tredje part i reklamsyfte.</p>
            <br/>
            <p>5.2 I tabellen nedan anges situationer då dina personuppgifter kan komma att lämnas ut
            till tredje part.
            Tredje part Anledningen till att person-uppgifterna lämnas ut till
            tredje part
            Andra bolag inom
            Bolagets koncern
            Personuppgifter kan behöva överföras till andra bolag
            inom koncernen eftersom centrala funktioner såsom
            marknadsföring och ekonomi sköts gemensamt inom den
            koncern som Bolaget ingår i.
            Leverantör av
            molntjänster
            Personuppgifter kan komma att lämnas ut till leverantörer
            av molntjänster eftersom Bolaget lagrar viss information i
            molntjänster.
            Leverantörer och
            samarbetspartners
            Bolaget kan komma att lämna ut dina personuppgifter till
            olika leverantörer och/eller samarbetspartner om sådana
            leverantörer eller samarbetspartner behöver dina personuppgifter för att kunna fullgöra sina uppdrag för Bolaget.
            Myndigheter Personuppgifter kan komma att överlämnas till
            myndigheter då det krävs för att uppfylla lagenliga
            skyldigheter.
            Avyttring För det fall Bolaget avser att överlåta hela eller delar av
            Bolagets verksamhet kan personuppgifter komma att
            lämnas ut till en potentiell köpare.</p>
            <br/>
            <p>6 ÖVERFÖRING AV PERSONUPPGIFTER TILL TREDJELAND</p>
            <br/>
            <p>Bolaget kommer att lagra dina personuppgifter inom EU/EES. Bolaget kan komma att överföra
            dina personuppgifter till länder utanför EU/EES. Om personuppgifter överförs till något land
            utanför EU/EES, kommer Bolaget att vidta åtgärder för att se till att personuppgifterna
            fortsätter vara skyddade och även vidta de åtgärder som krävs för att på lagligt sätt överföra
            personuppgifter till länder utanför EU/EES. </p>
            <br/>
            <p>13</p>
            <br/>
            <p>7 SOCIALA MEDIER</p>
            <br/>
            <p>Vad gäller de personuppgifter som förekommer och behandlas på sociala medier såsom
            Facebook, Instagram, Twitter, Youtube och LinkedIn hänvisar vi användare till respektive
            tjänsts policy för information om hur de behandlar personuppgifter. Enligt Bolaget är syftet
            med behandlingen att representanter för befintliga kunder, potentiella kunder och
            samarbetspartners till Bolaget ska kunna interagera och ha kontakt med Bolaget via sociala
            medier. Detta för att bidra till en god relation med kunder och samarbetspartners och för att
            göra Bolagets kundservice och produkt lättillgänglig genom flera olika kanaler. Behandlingen
            är nödvändig för Bolagets berättigade intresse av att marknadsföra sitt varumärke och sina
            produkter till befintliga och potentiella kunder samt till samarbetspartners (intresseavvägning).</p>
            <br/>
            <p>8 DINA RÄTTIGHETER</p>
            <br/>
            <p>En sammanfattning av dina rättigheter enligt tillämplig lagstiftning följer av tabellen nedan.
            Rätt till tillgång Du har rätt att få tillgång till dina personuppgifter samt att få ut
            en kopia av de personuppgifter som rör dig och som Bolaget
            behandlar.
            Rätt till rättelse Om de personuppgifter som Bolaget behandlar om dig är
            felaktiga, ofullständiga eller inaktuella, har du rätt att be oss att
            rätta dessa.
            Rätt till radering Du har rätt att begära att vi raderar dina personuppgifter. Om
            Bolaget inte har en rättslig grund för att fortsätta behandla
            personuppgifterna ska de raderas.
            Rätt att göra
            invändningar
            Under vissa omständigheter har du rätt att invända mot att
            Bolaget behandlar dina personuppgifter.
            Rätt till
            begränsning av
            behandling
            I vissa fall har du rätt att kräva att Bolaget begränsar
            behandlingen av dina personuppgifter. Detta innebär att Bolaget
            endast under vissa förutsättningar får behandla
            personuppgifterna på annat sätt än genom att lagra dem.
            Rätt till
            dataportabilitet
            Där personuppgifterna behandlas med ditt samtycke eller med
            stöd av ett avtal med dig har du rätt att få ut dina
            personuppgifter i ett maskinläsbart format samt överföra
            personuppgifterna till en annan personuppgiftsansvarig.
            Rätt att inge
            klagomål till en
            tillsynsmyndighet
            Du har rätt att inge klagomål om Bolagets behandling av dina
            personuppgifter till Integritetsskyddsmyndigheten, Box 8114,
            104 20 Stockholm.</p>
            <br/>
            <p>9 SKYDDET AV DINA PERSONUPPGIFTER</p>
            <br/>
            <p>Du ska alltid kunna känna dig trygg när du lämnar dina personuppgifter till oss. Bolaget har
            därför vidtagit de säkerhetsåtgärder som behövs för att skydda dina personuppgifter mot
            otillbörlig åtkomst, förändring och radering. Vi kommer inte att lämna ut dina uppgifter till någon
            annan, annat än vad som uttryckligen följer av denna Integritetspolicy.</p>
            <br/>
            <p>10 COOKIES</p>
            <br/>
            <p>Bolaget använder cookiesliknande tekniker i syfte att tillhandahålla vissa funktioner på
            webbplatserna www.airpark.app och portal.airpark.app samt för att förbättra webbplatserna</p>
            <br/>
            <p>14</p>
            <br/>
            <p>och att leverera en bättre och mer personlig service. Informationen lagras i form av en fil
            innehållande krypterade inloggningsdata.</p>
            <br/>
            <p>11 OM DU INTE LÄMNAR DINA PERSONUPPGIFTER TILL BOLAGET</p>
            <br/>
            <p>Om du inte lämnar dina personuppgifter till Bolaget kommer Bolaget inte att kunna fullgöra
            sina rättsliga eller avtalsenliga skyldigheter gentemot dig.</p>
            <br/>
            <p>12 ÄNDRINGAR</p>
            <br/>
            <p>Bolaget har rätt att när som helst ändra denna integritetspolicy. Vid ändringar av denna
            integritetspolicy kommer Bolaget att publicera den justerade integritetspolicyn på
            www.airpark.app med information om när ändringarna träder ikraft. Bolaget kan även komma
            att informera kunder och samarbetspartners på lämpligt sätt.</p>
            <br/>
            <p>13 KONTAKTINFORMATION</p>
            <br/>
            <p>Tveka inte att kontakta Bolaget om du har några frågor om denna integritetspolicy,
            behandlingen av dina personuppgifter eller om du vill utöva dina rättigheter enligt denna
            integritetspolicy eller tillämplig lagstiftning.
            <br/>
            AirPark AB
            <br/>
            Org.nr: 559350-3138
            <br/>
            c/o Hetch AB, Redaregatan 48, 252 36 Helsingborg
            <br/>
            Telefon: 010-6411868
            <br/>
            E-post: hello@airpark.app</p>
      
        </div>
    </>
    )
  };

export default TermsPage;